<template>
  <div>
    <v-container>
      <v-row>
        <v-col>
          <v-card>
            <v-data-table
              :headers="data.headers"
              :items="data.data"
              :items-per-page="5"
              :loading="data.load"
              sort-by="donation"
              class="elevation-0 border-solid"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title class="text-h5">
                    Historial de Mis Donaciones
                  </v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                </v-toolbar>
              </template>
              <!-- Column custom -->
              <template v-slot:[`item.transaction_amount`]="{ item }">
                $ {{ parseFloat(item.transaction_amount).toFixed(2) }}
              </template>
              <!-- Actions -->
              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      @click="donation(item)"
                      color="blue"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-eye
                    </v-icon>
                  </template>
                  <span>Ver Donación</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <a
                      :href="
                        item.voucher_status != null
                          ? api + 'pdf/' + item.voucher_status
                          : '#'
                      "
                      class="ml-4 text-decoration-none"
                    >
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        :color="
                          item.voucher_status != null
                            ? 'green'
                            : 'blue-grey darken-1'
                        "
                      >
                        {{
                          item.voucher_status != null
                            ? 'mdi-download'
                            : 'mdi-clock-alert-outline'
                        }}
                      </v-icon>
                    </a>
                  </template>
                  <span>{{
                    item.voucher_status != null
                      ? 'Descargar Voucher'
                      : 'Voucher pendiente'
                  }}</span>
                </v-tooltip>
              </template>
              <!-- Si no carga la data -->
              <template v-slot:no-data>
                <v-btn color="primary" @click="getDonations">
                  Recargar
                </v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <DonationDetail
      ref="mdlDetail"
      v-bind:donation="donationD"
    ></DonationDetail>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import DonationDetail from './DonationDetail';
export default {
  name: 'Donor',
  components: {
    DonationDetail,
  },
  data: () => ({
    dialog: false,
    donations: [],
    data: {
      load: false,
      headers: [
        { text: 'Donación para', value: 'donation_type' },
        { text: 'Monto de Donación', value: 'transaction_amount' },
        { text: 'Año lectivo', value: 'year' },
        { text: 'Fecha', value: 'transaction_time_format' },
        { text: 'Acciones', value: 'actions', sortable: false },
      ],
      data: [],
    },
    donationD: {},
  }),
  mounted() {
    this.getDonations();
  },
  methods: {
    ...mapActions({
      getDonor: 'Donor/getDonor',
      openSnackbar: 'Snackbar/openSnackbar',
    }),

    getDonations: async function() {
      this.data.load = true;
      await this.$http
        .get(this.api + 'tx/donor')
        .then(({ data }) => {
          this.data.data = data;
        })
        .catch((err) => {
          this.openSnackbar({
            type: 'danger',
            message: 'Error al obtener los datos',
          });
        })
        .then(() => {
          this.data.load = false;
        });
    },

    donation(item) {
      this.donationD = {};
      this.donationD = item;
      this.$refs.mdlDetail.changeModal();
    },
  },
  computed: {
    ...mapGetters({
      api: 'getApi',
      token: 'Donor/getToken',
      user: 'Donor/getDonor',
    }),
  },
};
</script>

<style></style>
