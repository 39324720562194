var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',[_c('v-row',[_c('v-col',[_c('v-card',[_c('v-data-table',{staticClass:"elevation-0 border-solid",attrs:{"headers":_vm.data.headers,"items":_vm.data.data,"items-per-page":5,"loading":_vm.data.load,"sort-by":"donation"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',{staticClass:"text-h5"},[_vm._v(" Historial de Mis Donaciones ")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}})],1)]},proxy:true},{key:"item.transaction_amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" $ "+_vm._s(parseFloat(item.transaction_amount).toFixed(2))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"blue"},on:{"click":function($event){return _vm.donation(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-eye ")])]}}],null,true)},[_c('span',[_vm._v("Ver Donación")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('a',{staticClass:"ml-4 text-decoration-none",attrs:{"href":item.voucher_status != null
                        ? _vm.api + 'pdf/' + item.voucher_status
                        : '#'}},[_c('v-icon',_vm._g(_vm._b({attrs:{"color":item.voucher_status != null
                          ? 'green'
                          : 'blue-grey darken-1'}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(item.voucher_status != null ? 'mdi-download' : 'mdi-clock-alert-outline')+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.voucher_status != null ? 'Descargar Voucher' : 'Voucher pendiente'))])])]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.getDonations}},[_vm._v(" Recargar ")])]},proxy:true}],null,true)})],1)],1)],1)],1),_c('DonationDetail',{ref:"mdlDetail",attrs:{"donation":_vm.donationD}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }